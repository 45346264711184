
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine, getTestPort } from "@/utils/common";
import { Loading, Pageniation, TableRowsSelect } from "../../utils/decorator";
import dayjs from "dayjs";
import { formDownload } from "../../utils/tools";
import tableCheck from "@/mixins/table-check";
import t from "@common/src/i18n/t";
const management = namespace("management");
const supplier = namespace("supplier");
const base = namespace("base");
const app = namespace("app");
const packageInfo = namespace("packageInfo");

import SwitchStatus from "@/components/scope/switch-status.vue";

@Component({
  components: { TableEnableFlag ,SwitchStatus}
})
@Pageniation("loadListData")
export default class StoreList extends Mixins(tableCheck) {
  @base.Action getAreaList;
  @management.Action getStoreList;
  @management.Action getCashierSubjectList;
  @management.Action saveCashierSubject;
  @management.Action getCashierSubject;
  @management.Action getServiceItem;
  @management.Action saveStoreServiceItem;
  @management.Action queryAllChannel;
  @supplier.Action updateStatus;
  @management.Action updateEnableStore;
  @management.Action updateDisableStore;
  @management.Action resendPhoneMessage;
  @base.Action getDictionaryList;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @packageInfo.Action packageEnableTempLate;
  @packageInfo.Action saveStoreEnableServiceItem;

  dayjs = dayjs;
  searchForm = {
    storeName: "",
    area: "",
    stateName: "",
    cityName: "",
    districtName: "",
    userFirstName: "",
    userMobile: "",
    storeType: "",
    openChannelList: []
  };
  emptyToLine = emptyToLine;
  storeList: any = [];
  activeRow: any = {};
  showForbidTips: boolean = false;
  shwoResendModal: boolean = false;
  storeSourceList: any[] = [];
  openChannelList: any[] = [];
  storeTypeList: any = [];
  cashierSubjectDialogVisible:boolean =  false;
  cashierSubject:any = {
    storeCode: '',
    subjectCode: '',
  };
  cashierSubjectList:any= [];
  get searchList() {
    return [
      {
        label: t("v210831.store-name"),
        type: "input",
        value: "",
        prop: "storeName"
      },
      {
        label: t("v210831.store-number"),
        type: "input",
        value: "",
        prop: "storeCode"
      },
      {
        label: t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true
      },
      {
        label: t("v210831.person-in-charge"),
        type: "input",
        value: "",
        prop: "userFirstName"
      },
      {
        label: t("v210831.store-mobile-person-in-charge"),
        type: "input",
        value: "",
        prop: "userMobile"
      },
      {
        label: t("v210831.application-source"),
        type: "select",
        selectList: this.storeSourceList,
        value: "",
        prop: "storeSource"
      },
      {
        label: t("v210831.store-type"),
        type: "select",
        value: "",
        selectList: this.storeTypeList,
        prop: "storeType"
      }
    ];
  }

  handleSearch(data) {
    // console.log(data);
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "area") {
          this.searchForm.stateName = item.labels && item.labels[0] ? item.labels[0] : "";
          this.searchForm.cityName = item.labels && item.labels[1] ? item.labels[1] : "";
          this.searchForm.districtName = item.labels && item.labels[2] ? item.labels[2] : "";
        } else if (item.prop === "openChannelList") {
          this.searchForm.openChannelList = item.value === "" ? [] : item.value;
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  created() {
    this.init();
    this.loadCashierSubjectList();
  }
  @Loading()
  loadListData() {
    return this.getStoreList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      console.log("storeList", data);
      this.storeList = data.data.list;
      return data;
    });
  }
  loadCashierSubjectList(){
    return this.getCashierSubjectList().then(data => {
      this.cashierSubjectList = data.data;
      console.log(this.cashierSubjectList)
    });
  }
  async init() {
    this.loadListData();
    // 审核状态字典
    this.getDictionaryList("store_source").then(data => {
      // this.applyStatusList = data;
      console.log(data, "data-store");
      this.storeSourceList = data;
    });
    // this.queryAllChannel().then(data => {
    //   console.log("[ data ]", data);
    //   this.openChannelList = data.data.map(item => {
    //     return {
    //       label: item.channelName,
    //       value: item.channelCode
    //     };
    //   });
    // });
    this.getDictionaryList("store_type").then(data => {
      console.log("[ storeTypeList ]", data);
      this.storeTypeList = data;
    });
  }
  handleImportStore() {
    (this.$refs.refUpload as any).handleOpen();
  }
  handleShowResendModal(row) {
    this.activeRow = row;
    this.shwoResendModal = true;
  }
  handleExportData() {
    formDownload(`${getTestPort()}/op/store/exportFinancialInfo`, this.searchForm);
  }
  handleView(row) {
    this.$router.push(`/management/store-view/${row.storeCode}`);
  }

  handleEditView(row){
    this.$router.push(`/management/store-edit-new/${row.storeCode}`);
  }

  cashierSubjectView(row){
    this.cashierSubjectDialogVisible = true;
    this.cashierSubject.storeCode = row.storeCode;
    console.log(row)
    this.getCashierSubject(this.cashierSubject.storeCode).then(data=>{
      if(data.data){
        this.cashierSubject.subjectCode = data.data.subjectCode;
      }
    })
  }

  cashierSubjectSave(){
    const param  = this.cashierSubjectList.find(item => item.subjectCode === this.cashierSubject.subjectCode);
    console.log(param)
    this.saveCashierSubject({...param, storeCode: this.cashierSubject.storeCode}).then(data => {
      if(data.code === '0'){
        this.$message.success("设置成功");
        this.cashierSubjectDialogVisible = false;
      }else{
        this.$message.error(data.msg);
      }
    });
  }


  handleResendPhoneMessage() {
    this.btnStartLoading();
    this.resendPhoneMessage({
      storeCode: this.activeRow.storeCode
    })
      .then(data => {
        this.$message.success(t("v210831.sent-successfully"));
        this.shwoResendModal = false;
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  // handleBatchStart() {
  //   if (this.mix_selectedRow.length < 1) {
  //     this.$message.warning(t("finance.please-select-supplier"));
  //   } else {
  //     this.confirmUpdateStatus(1);
  //   }
  // }
  // handleBatchForbid() {
  //   if (this.mix_selectedRow.length < 1) {
  //     this.$message.warning(t("finance.please-select-supplier"));
  //   } else {
  //     this.showForbidTips = true;
  //   }
  // }
  confirmUpdateStatus(status,row) {
    if (status) {
      this.updateEnableStore({
        // storeCode: this.mix_selectedRow.map(item => item.storeCode)[0]
        storeCode: row.storeCode
      }).then(data => {
        this.$message.success(t("setting.enable-successful"));
        this.showForbidTips = false;
        this.loadListData();
      });
    } else {
      this.updateDisableStore({
        // storeCode: this.mix_selectedRow.map(item => item.storeCode)[0]
        storeCode: row.storeCode
      }).then(data => {
        this.$message.success(t("setting.disable-success"));
        this.showForbidTips = false;
        this.loadListData();
      });
    }
  }

  @Loading()
  loadServiceItemData(storeCode) {
    return this.getServiceItem({
      storeCode: storeCode
    }).then(data => {
      this.serviceItemData = data.data.list;
      this.configuredServiceCode = data.data.configuredServiceCode;
      return data;
    });
  }

  setStoreCode: any = "";
  serviceItemData: any=[];
  configuredServiceCode: any=[];
  serviceItemDialogVisible: boolean = false;
  serviceItemTreeProps: any = {
    id: 'code',
    children: 'children',
    label: 'name'

  }
  handleServiceItem(row){
    this.serviceItemData = [];
    this.configuredServiceCode = [];
    this.setStoreCode = row.storeCode;
    this.loadServiceItemData(row.storeCode);
    this.serviceItemDialogVisible = true;
  }

  serviceItemDialogSave(){
    let ref: any = this.$refs.serviceItemTree
    let choseService = ref.getCheckedKeys(true);

    let list = [];
    for (let k of choseService) {
      //对比数组每一项，没有的就添加到空数组
      let indexof = this.configuredServiceCode.indexOf(k);
      if (indexof < 0) {
        list.push(k);
      }
    }

    this.saveStoreServiceItem({
      storeCode: this.setStoreCode,
      serviceCodes: list
    }).then(data => {
      if (data.code == 0) {
        this.$message({
          message: '设置成功',
          type: 'success'
        });
        this.serviceItemDialogVisible = false;
      }
    });
  }

  packageTemplateData: any = [];
  configuredPackageTemplateCode: any = [];
  packageTemplateDialogVisible: boolean = false;
  packageTemplateTreeProps: any = {
    id: 'packageCode',
    children: 'children',
    label: 'packageName',
    disabled: "status"

  }
  handlePackageInfo(row){
    this.packageTemplateData = [];
    this.configuredPackageTemplateCode = [];
    this.setStoreCode = row.storeCode;
    this.loadPackageTemplateData(row.storeCode);
    this.packageTemplateDialogVisible = true;
  }

  @Loading()
  loadPackageTemplateData(storeCode) {
    return this.packageEnableTempLate({
      storeCode: storeCode
    }).then(data => {
      this.packageTemplateData = data.data.list;
      this.configuredPackageTemplateCode = data.data.configuredTemplateCode;
      console.log(this.configuredPackageTemplateCode)
      return data;
    });
  }

  packageTemplateDialogSave(){
    let ref: any = this.$refs.packageTemplateTree
    let choseService = ref.getCheckedKeys(true);

    console.log(choseService)
    console.log(this.configuredPackageTemplateCode)
    let list = [];
    for (let k of choseService) {
      //对比数组每一项，没有的就添加到空数组
      let indexof = this.configuredPackageTemplateCode.indexOf(k);
      if (indexof < 0) {
        list.push(k);
      }
    }

    this.saveStoreEnableServiceItem({
      storeCode: this.setStoreCode,
      packageCodes: list
    }).then(data => {
      if (data.code == 0) {
        this.$message({
          message: '设置成功',
          type: 'success'
        });
        this.packageTemplateDialogVisible = false;
      }
    });
  }
}
